<template>
  <div class="section">
    <h1 class="overskrift-hvit">Oppskrifter</h1>
    <p class="is-size-4 is-size-5-mobile">Her er alle oppskriftene. Filtreringsmuligheter kommer etter hvert :-)</p>

    <ApolloQuery :query="require('../graphql/previewPosts.gql')">
      <template slot-scope="{ result: { error, data }, isLoading } ">
        <div v-if="isLoading">Laster...</div>
        <div v-else-if="error">En feil har oppstått!</div>
        <div v-if="data">                            
          <div v-for="post in data.posts" :key="post.id">
            <div class="columns">
              <div class="column is-one-quarter">    
                <figure class="image is-16by9">
                  <router-link :to="post.slug">
                    <img :src="post.coverImage.url" :alt="post.title" />
                  </router-link>
                </figure>              
              </div>
              <div class="column is-three-quarters">
                <h3 class="overskrift-hvit">
                  <router-link :to="post.slug">
                    {{post.title}}
                  </router-link>
                </h3>
                {{ post.excerpt }}

                <br/>
                <span class="is-size-6 is-italic" v-for="tag in post.tags" :key="tag.tags">
                  {{tag}}
                </span>

                <span class="is-size-6 is-italic"> // Publisert {{ moment(post.date).format('LL') }}</span>

                <p class="utenAvsnitt is-size-5 is-size-6-mobile">
                  <router-link :to="post.slug">
                    Les mer
                  </router-link>
                </p>
                                
              </div>  
            </div>
          </div>
        </div>    
      </template>      
    </ApolloQuery>
    

  </div>
</template>

<script>
// @ is an alias to /src
//import _ from 'lodash';
//import Query from '../graphql/getTags.gql'


export default {
  name: 'Oppskrifter',
  components: {
  },
  data(){
    return{
  //     filteredTags: [],
  //     staticTags: [],     
  //     tags: [], 
  //     tagsLoaded: false,
  //     allowNew: false,
  //     isSelectOnly: false,
  //     page:1,
  //     hasMore: true
    }
  },
  // apollo: {
  //     posts: {
  //         query: Query
  //     }
  // },
  methods: {
    // async loadMore(query) {
    //   await query.fetchMore({
    //     variables: {
    //       offset: this.page++ * this.$options.pageSize
    //     },
    //     updateQuery: (prev, {fetchMoreResult }) => {
    //       if (!fetchMoreResult || fetchMoreResult.title.length === 0) {
    //         this.hasMore = false
    //         return prev
    //       }
    //       return Object.assign({}, prev, {
    //         title: [...prev.title, ...fetchMoreResult.title]
    //       })
    //     }
    //   })
    // },
    //  unikeTagger: function() {
    //    // Skjønner ikke hvorfor, men Apollo må ha litt tid på seg. 
    //    // Samler alle tagger til ett array
    //    setTimeout(() => {
    //     let x = this.posts.length;
    //     let i = 0;
    //     let tagger = []
    //     while (i < x) {
    //       tagger.push(this.posts[i].tags)         
    //       i++
    //     }
    //     // Pynter på arrayet og lager et par kopier
    //     let y = _.flattenDeep(tagger)               
    //     let z = _.uniq(y)
    //     this.filteredTags = Object.keys(z).map(id => ({ id, tag: z[id] }));
    //     this.staticTags = this.filteredTags
    //     this.tags = this.staticTags
    //     this.tagsLoaded = true        
    //    }, 100);
    //  },
    //   getFilteredTags(text) {
    //       this.filteredTags = this.staticTags.filter((option) => {
    //           return option.tag
    //               .toString()
    //               .toLowerCase()
    //               .indexOf(text.toLowerCase()) >= 0
    //       })
    //   }
    // },
  // mounted() {    
  //   this.unikeTagger();    
 }
}
</script>
